import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,
  Breakpoint,

  useSweetName,
  useCountDecimals,
  useDimensions,

  Text,
  Input,
  Button,
  Status,
  OverlaySearch,
  Item, ConfirmDialog,
} from '@bluecurrent/web-component-lib';

import {
  SET_OPERATIVE,
  SET_INOPERATIVE,
  REBOOT,
  SET_CHARGE_POINT_NAME,
  GET_CHARGE_CARDS_PAGED,
  GET_TARIFFS,
  EDIT_TARIFF,
  UNLOCK_CONNECTOR,
  SET_PLUG_AND_CHARGE,
  SET_PLUG_AND_CHARGE_CHARGE_CARD,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';
import addNotification from '../../../redux/creators/notifications';
import useCryptoService from '../../../hooks/CryptoService';
import softReset from '../../../hooks/commands/softReset';

import AreYouSure from './AreYouSure';
import PlugAndChargeSetting from './ChargePointSettings/PlugAndChargeSetting';
import PublicChargingSetting from './ChargePointSettings/PublicChargingSetting';
import LedInteractionSetting from './ChargePointSettings/LedInteractionSetting';
import PublishLocationSetting from './ChargePointSettings/PublishLocationSetting';
import ProfiledChargingSetting from './ChargePointSettings/ProfiledChargingSetting';
import ProfiledCharging from './ProfiledCharging';
import ProfiledChargingSettings from '../../../config/ProfiledCharging.config';
import RoamingTariffSetting from './ChargePointSettings/RoamingTariffSetting';
import RoamingTariff from './RoamingTariff';
import CapacityTariffSetting from './ChargePointSettings/CapacityTariffSetting';
import CapacityTariff from './CapacityTariff';

export default function ChargePointSettings(props) {
  const { _, i18n } = Lang.useTranslation();
  const evseSettings = useSelector(
    (state) => state.ChargePoints.find((i) => i.evse_id === props.serial),
  );

  const sweetName = useSweetName(_);
  const dispatch = useDispatch();
  const countDecimals = useCountDecimals();
  const cryptoService = useCryptoService();
  const navigate = useNavigate();
  const size = useDimensions();
  const [, setRebootDialog] = props.useRebootDialog;

  /* If a user doesn't have access to a chargepoint */
  if (!evseSettings) {
    navigate('/chargepoints');
    return null;
  }

  const [updatedSettings, setUpdatedSettings] = React.useState({});
  const [loadingButtons, setLoadingButtons] = React.useState([]);
  const [chargePointName, setChargePointName] = React.useState(evseSettings.name ?? '');
  const [nameTimeout, setNameTimeout] = React.useState(null);
  const [savedName, setSavedName] = React.useState(false);

  const [hoverUnavailable, setHoverUnavailable] = React.useState(false);

  const [passwordVerification, setPasswordVerification] = React.useState(null);
  const [passwordVerificationSetting, setPasswordVerificationSetting] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordConfirmFunction, setPasswordConfirmFunction] = React.useState(null);

  const [selectPlugAndChargeCard, setSelectPlugAndChargeCard] = React.useState(false);
  const [possibleCards, setPossibleCards] = React.useState([]);
  const [possibleCardsLoading, setPossibleCardsLoading] = React.useState(false);

  const [selectTariff, setSelectTariff] = React.useState(false);
  const [tariffList, setTariffList] = React.useState([]);
  const [tariffSearch, setTariffSearch] = React.useState('');
  const [showPublishWarning, setShowPublishWarning] = React.useState(false);

  const [isProfiledChargingModal, setProfiledChargingModal] = React.useState(false);
  const [isRoamingTariffModalOpen, setRoamingTariffModalOpen] = React.useState(false);

  const [capacityTariffModal, setCapacityTariffModal] = React.useState(false);

  React.useEffect(() => {
    if (evseSettings.name) {
      props.setModalTitle(evseSettings.name);
      props.setModalSubTitle(props.serial);
    } else {
      props.setModalTitle(props.serial);
      props.setModalSubTitle(null);
    }
  }, [evseSettings.name]);

  React.useEffect(() => {
    if (selectPlugAndChargeCard) {
      props.setModalTitle(_('selectPlugAndChargeCard', { ns: 'ui' }));
      props.setModalSubTitle(evseSettings.name || props.serial);
    } else {
      props.setModalTitle(evseSettings.name || props.serial);
      props.setModalSubTitle(evseSettings.name && props.serial);
    }
  }, [selectPlugAndChargeCard]);

  const isDelayedCharging = evseSettings.vehicle_status === 'C' && (
    evseSettings.smart_current_heartbeat_timeout !== undefined
      ? evseSettings.smart_current_heartbeat_timeout > 0
      : evseSettings.delayed_charging.smart_current_heartbeat_timeout > 0
  );

  const loadTariffs = (callback) => {
    executeCommand(GET_TARIFFS, {
      params: { evse_id: props.serial },
    })
      .then(({ data }) => {
        callback(data);
      })
      .catch(() => {
        callback(false);
      });
  };

  const updateTariff = (tariff = null, password = null) => {
    const request = (newTariff) => {
      executeCommand(EDIT_TARIFF, {
        body: {
          tariff_id: newTariff.tariff_id,
          evse_id: props.serial,
          password: cryptoService.encryptPassword(password),
        },
      })
        .then(({ data }) => {
          if (data.error === 8) {
            setPasswordError(_('errors.invalidPassword', { ns: 'authentication' }));
            return;
          }

          setPasswordVerification(null);

          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.serial,
            payload: {
              tariff: {
                ...newTariff,
                permission: evseSettings.tariff.permission,
                currency: evseSettings.tariff.currency,
              },
              publish_location: {
                value: newTariff.tariff_id === 'NLBCUT1' ? false : evseSettings.publish_location?.value,
                permission: evseSettings.publish_location.permission,
              },
            },
          });
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        });
    };

    setSelectTariff(false);
    if (!tariff) {
      if (tariffList.length === 0) {
        loadTariffs((data) => {
          if (!data.tariffs) {
            Sentry.captureEvent(`Could not load Tarrifs, data.tariffs: ${JSON.stringify(data.tariffs)}`);
            dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
          } else {
            setTariffList(data.tariffs.filter(
              (i) => countDecimals(i.price_ex_vat) <= 2
                || i.tariff_id === evseSettings.tariff.tariff_id,
            ));
            const newTariff = data.tariffs.find((i) => i.tariff_id === 'NLBCUT1');
            request(newTariff);
          }
        });
      } else {
        const newTariff = tariffList.find((i) => i.tariff_id === 'NLBCUT1');
        request(newTariff);
      }
    } else {
      request(tariff);
    }
  };

  const cancelSetting = (key, value) => {
    const resetPayload = {
      [key]: !value,
    };

    setUpdatedSettings({ ...updatedSettings, ...resetPayload });
  };

  const continueTask = (password, setting, key, value, callback) => {
    if (setting) {
      executeCommand(setting, {
        evse_id: props.serial,
        value,
        password: cryptoService.encryptPassword(password),
      })
        .then(() => {
          setPasswordVerification(null);
          callback(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (!error.receivedCommandSuccesful && error.result?.error === 'incorrect password') {
            setPasswordError(_('errors.invalidPassword', { ns: 'authentication' }));
            return;
          }
          setPasswordVerification(null);
          const errors = {
            SET_PLUG_AND_CHARGE: 'plugAndChargeFailed',
            SET_PUBLIC_CHARGING: 'publicChargingFailed',
            SET_SMART_CHARGING: 'smartChargingFailed',
            SET_PLUG_AND_CHARGE_NOTIFICATION: 'plugAndChargeNotificationFailed',
            SET_LED_INTERACTION: 'ledInteractionFailed',
          };
          dispatch(addNotification('failed', _(`error.${errors[error.request.command]}`, { ns: 'chargepoint', id: props.serial }), 'red'));

          cancelSetting(key, value);
        });
    }
  };

  const simpleValueChange = (setting, key, value, dangerous, callback) => {
    const payload = {
      [key]: value,
    };

    setUpdatedSettings({ ...updatedSettings, ...payload });

    if (dangerous) {
      setPasswordVerificationSetting(key);
      setPasswordVerification([setting, key, value, callback]);
    } else {
      continueTask(null, setting, key, value, callback);
    }
  };

  const setPlugAndChargeCard = (card) => {
    const continueDefaultCardTask = () => {
      executeCommand(SET_PLUG_AND_CHARGE_CHARGE_CARD, {
        evse_id: props.serial,
        token_uid: card.uid,
      })
        .then(() => {
          setSelectPlugAndChargeCard(false);
          setPossibleCards([]);
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.serial,
            payload: {
              plug_and_charge_charge_card: card,
            },
          });
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        });
    };

    if (selectPlugAndChargeCard === 'enabling') {
      const payload = {
        plug_and_charge: {
          value: true,
          permission: evseSettings.plug_and_charge?.permission ?? 'error',
        },
      };

      simpleValueChange(
        SET_PLUG_AND_CHARGE,
        'plug_and_charge',
        true,
        true,
        (success) => {
          if (success) {
            dispatch({
              type: 'UPDATE_SETTINGS',
              id: evseSettings.evse_id,
              payload,
            });

            continueDefaultCardTask();
          }
        },
      );
    } else {
      continueDefaultCardTask();
    }
  };

  // Detects which profiled settings is enabled
  React.useEffect(() => {
    Object.keys(ProfiledChargingSettings).forEach((a) => {
      if (evseSettings[a]?.value === true) {
        return dispatch({
          type: 'UPDATE_SETTINGS',
          id: evseSettings.evse_id,
          payload: {
            profiledcharging: a,
          },
        });
      }
      return null;
    });
  }, []);

  React.useEffect(() => {
    if (selectPlugAndChargeCard && possibleCards.length === 0) {
      setPossibleCardsLoading(true);
      executeCommand(GET_CHARGE_CARDS_PAGED, {
        params: {
          other: 'N',
          evse_id: props.serial,
        },
      })
        .then(({ data }) => {
          const cards = data.cards.map((i) => ({
            id: i.id,
            name: i.name,
            serial: i.id,
            type: 'chargecard',
            selected: i.id === evseSettings.plug_and_charge_charge_card?.id,
            onClick: () => setPlugAndChargeCard(i),
          }));

          cards.unshift({
            id: 'BCU-APP',
            name: _('noChargeCard', { ns: 'ui' }),
            type: 'chargepoint',
            icon: {
              name: 'UMOVE',
              height: 50,
              width: 50,
              color: Colors.GREY,
              iconSet: 'BlueCurrent',
            },
            selected: evseSettings.plug_and_charge_charge_card?.id === 'BCU-APP' || !evseSettings.plug_and_charge_charge_card,
            onClick: () => setPlugAndChargeCard({ id: 'BCU-APP', uid: 'BCU-APP' }),
          });

          setPossibleCards(cards);
          setPossibleCardsLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        });
    }
  }, [selectPlugAndChargeCard]);

  const actions = [
    {
      id: 'unavailable',
      visible: evseSettings.notConfirmedSession !== 'SOFT_RESET',
      title: _('chargePoint', { ns: 'ui' }),
      subText: evseSettings.activity === 'unavailable'
        ? _('unblock', { ns: 'ui' })
        : _('block', { ns: 'ui' }),
      icon: {
        name: evseSettings.activity !== 'unavailable'
          ? hoverUnavailable ? 'Lock' : 'Unlock'
          : hoverUnavailable ? 'Unlock' : 'Lock',
        type: 'light',
        height: 35,
        width: 35,
        set: 'FA',
        transform: false,
      },
      disabled: evseSettings.activity === 'charging',
      color: undefined,
      selected: evseSettings.activity === 'unavailable',
      colorScheme: 'black',
      loading: loadingButtons.includes('available'),
      onMouseEnter: () => evseSettings.activity !== 'charging' && setHoverUnavailable(true),
      onMouseLeave: () => setHoverUnavailable(false),
      onClick: () => {
        if (evseSettings.activity === 'charging') {
          dispatch(addNotification('failed', _('error.availableFailedStillCharging', { ns: 'chargepoint', id: props.serial }), 'red'));
          return;
        }

        const wasUnavailable = evseSettings.activity === 'unavailable';
        setLoadingButtons([...loadingButtons, 'available']);
        executeCommand(wasUnavailable ? SET_OPERATIVE : SET_INOPERATIVE, { evse_id: props.serial })
          .then(() => {
            dispatch(addNotification('success', _('actions.availabilityChanged', { ns: 'chargepoint', id: props.serial }), 'green'));

            setUpdatedSettings({ ...updatedSettings, activity: wasUnavailable ? 'available' : 'unavailable' });
            dispatch({
              type: 'UPDATE_SETTINGS',
              id: props.serial,
              activity: wasUnavailable ? 'available' : 'unavailable',
            });
          })
          .catch((err) => {
            Sentry.captureException(err);
            dispatch(addNotification('failed', _('error.availableFailed', { ns: 'chargepoint', id: props.serial }), 'red'));
          })
          .finally(() => setLoadingButtons(loadingButtons.filter((n) => n !== 'available')));
      },
    },
    {
      id: 'unlock',
      visible: (!evseSettings.is_cable && evseSettings.chargepoint_type === 'UMOVE') || !evseSettings.chargepoint_type === 'HIDDEN',
      title: _('cable', { ns: 'ui' }),
      subText: _('unlock', { ns: 'ui' }),
      icon: {
        name: 'Plug',
        type: 'light',
        height: 40,
        width: 40,
        set: 'FA',
        transform: false,
      },
      color: undefined,
      selected: false,
      colorScheme: 'black',
      loading: loadingButtons.includes('unlock'),
      onMouseEnter: false,
      onMouseLeave: false,
      onClick: () => {
        setLoadingButtons((prev) => [...prev, 'unlock']);
        executeCommand(UNLOCK_CONNECTOR, { evse_id: props.serial })
          .then(() => dispatch(addNotification('success', _('actions.unlocked', { ns: 'chargepoint', id: props.serial }), 'green')))
          .catch((err) => {
            Sentry.captureException(err);
            dispatch(addNotification('failed', _('error.unlockFailed', { ns: 'chargepoint', id: props.serial }), 'red'));
          })
          .finally(() => setLoadingButtons((prev) => prev.filter((n) => n !== 'unlock')));
      },
    },
  ];

  const commands = [
    {
      id: 'reset',
      visible: evseSettings.activity !== 'unavailable',
      title: _('chargePoint', { ns: 'ui' }),
      subText: _('actions.reset', { ns: 'chargepoint' }),
      icon: {
        name: 'Sync',
        type: 'light',
        height: 30,
        width: 30,
        set: 'FA',
        transform: false,
      },
      color: undefined,
      selected: false,
      colorScheme: 'black',
      loading: loadingButtons.includes('reset'),
      onClick: () => {
        setLoadingButtons([...loadingButtons, 'reset']);
        props.onClose();
        softReset(props.serial, _, () => setLoadingButtons(loadingButtons.filter((n) => n !== 'reset')));
      },
    },
    {
      id: 'reboot',
      visible: evseSettings.activity !== 'unavailable',
      title: _('chargePoint', { ns: 'ui' }),
      subText: _('actions.reboot', { ns: 'chargepoint' }),
      icon: {
        name: 'PowerOff',
        type: 'light',
        height: 30,
        width: 30,
        set: 'FA',
        transform: false,
      },
      color: Colors.RED,
      selected: false,
      colorScheme: 'red',
      loading: loadingButtons.includes('reboot'),
      onMouseEnter: false,
      onMouseLeave: false,
      onClick: () => setRebootDialog(true),
    },
  ];

  const reboot = () => {
    setLoadingButtons([...loadingButtons, 'reboot']);
    executeCommand(REBOOT, { evse_id: props.serial })
      .then(() => dispatch(addNotification('success', _('actions.rebooted', { ns: 'chargepoint', id: props.serial }))))
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.rebootFailed', { ns: 'chargepoint', id: props.serial })));
      })
      .finally(() => setLoadingButtons(loadingButtons.filter((n) => n !== 'reboot')));
  };

  React.useEffect(() => {
    props.setRebootFunc(reboot);
  }, []);

  const updateName = (name) => {
    setSavedName(false);
    if (nameTimeout) {
      clearTimeout(nameTimeout);
      setNameTimeout(null);
    }

    setNameTimeout(setTimeout(() => {
      const currentName = `${evseSettings.name}`;

      executeCommand(SET_CHARGE_POINT_NAME, {
        evse_id: props.serial,
        name,
      })
        .then(() => {
          setSavedName(true);
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.serial,
            payload: {
              name,
            },
          });
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.serial,
            payload: {
              name: currentName,
            },
          });
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        });
    }, 1000));
  };

  if (passwordVerification) {
    return (
      <AreYouSure
        onClose={() => setPasswordVerification(null)}
        content={passwordVerificationSetting}
        onConfirm={
          passwordConfirmFunction
            ? (pass) => passwordConfirmFunction(pass, ...passwordVerification)
            : (pass) => continueTask(pass, ...passwordVerification)
        }
        onCancel={() => {
          if (!passwordConfirmFunction) {
            cancelSetting(passwordVerification[1], passwordVerification[2]);
          }

          setPasswordVerification(null);
        }}
        useError={[passwordError, setPasswordError]}
        currentChargePoint={sweetName(evseSettings.chargepoint_type)}
        value={passwordVerification[2]}
      />
    );
  }

  if (selectPlugAndChargeCard) {
    return (
      <OverlaySearch
        loading={possibleCardsLoading}
        data={possibleCards}
      />
    );
  }

  if (selectTariff) {
    return (
      <>
        <div
          style={{
            height: 'auto',
            width: '100%',
            borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Text>{_('settings.tariffHint', { ns: 'chargepoint' })}</Text>
        </div>
        <OverlaySearch
          loading={tariffList.length === 0}
          onSearch={(e) => setTariffSearch(e.target.value)}
          data={
            tariffList.filter(
              (i) => tariffSearch === ''
                || i.tariff_id.toLowerCase().includes(tariffSearch.toLowerCase())
                || String(i.price_ex_vat.toFixed(2)).includes(tariffSearch),
            ).map((i) => ({
              id: i.tariff_id,
              type: 'tariff',
              text: i.tariff_id === 'NLBCUT1' ? 'Gratis' : null,
              amount: i.price_ex_vat,
              currency: i.currency,
              onClick: () => {
                if (i.tariff_id === 'NLBCUT1') {
                  setShowPublishWarning(true);
                  return;
                }

                setPasswordVerificationSetting('tariff');
                setPasswordVerification([i.tariff_id]);
                /* The double function might look weird,
               but it is how storing functions in state works */
                setPasswordConfirmFunction(() => (pass) => updateTariff(i, pass));
              },
              hideVat: true,
              selected: evseSettings.tariff.tariff_id === i.tariff_id,
            }))
          }
        />
        <ConfirmDialog
          visible={showPublishWarning}
          title={_('settings.publishLocation', { ns: 'chargepoint' })}
          description={_('warning.tariffPublishLocationOff', { ns: 'chargepoint' })}
          accept={{
            text: 'Doorgaan',
            onClick: () => {
              setShowPublishWarning(false);
              setPasswordVerificationSetting('tariff');
              setPasswordVerification([null]);
              /* The double function might look weird,
               but it is how storing functions in state works */
              setPasswordConfirmFunction(() => (pass) => updateTariff(null, pass));
            },
          }}
          cancel={{
            text: 'Annuleren',
            onClick: () => setShowPublishWarning(false),
          }}
        />
      </>
    );
  }

  if (isProfiledChargingModal) {
    return (
      <ProfiledCharging
        serial={props.serial}
        evseSettings={evseSettings}
        onClose={() => setProfiledChargingModal(false)}
        setProfiledChargingModal={setProfiledChargingModal}
      />
    );
  }

  if (isRoamingTariffModalOpen) {
    return (
      <RoamingTariff
        serial={props.serial}
        onClose={() => setRoamingTariffModalOpen(false)}
        evseSettings={evseSettings}
      />
    );
  }

  if (capacityTariffModal) {
    return (
      <CapacityTariff
        serial={props.serial}
        evseSettings={evseSettings}
        onClose={() => setCapacityTariffModal(false)}
      />
    );
  }

  return (
    <div
      id="Tour_Step4"
      style={{
        height: '100%',
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 100,
        overflowY: 'scroll',
      }}
    >
      <Input
        type="text"
        label={_('name', { ns: 'ui' })}
        name="chargepoint-name"
        styling
        max={(chargePointName !== evseSettings.name || !evseSettings.name) ? 20 : null}
        onChange={(e) => {
          setChargePointName(e.target.value.slice(0, 20));
          updateName(e.target.value.slice(0, 20));
        }}
        value={chargePointName}
        hint={savedName && _('saved', { ns: 'ui' })}
      />
      <div
        style={{
          marginTop: 30,
          width: '100%',
          height: 'auto',
        }}
      >
        <Text
          fontSize="1em"
          color={Colors.DARK_GREY}
          fontWeight={500}
        >
          {_('information', { ns: 'ui' })}
        </Text>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <div
            className="DisableSelection"
            style={{
              padding: 10,
              height: 'auto',
              backgroundColor: Colors.LIGHT_GREY,
              border: `solid 2px ${Colors.MEDIUM_WHITE}`,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 10,
                minHeight: 45,
              }}
            >
              <Text>{_('activityStatus', { ns: 'chargepoint' })}</Text>
              <Status
                status={
                  isDelayedCharging
                    ? 'delayed'
                    : evseSettings.activity
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingTop: 17,
                minHeight: 45,
                paddingBottom: 17,
              }}
            >
              <Text>{_('chargePoint', { ns: 'ui' })}</Text>
              <Text>{evseSettings.evse_id}</Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingTop: 10,
                minHeight: 45,
              }}
            >
              <Text>{_('model', { ns: 'ui' })}</Text>
              <Text>{sweetName(evseSettings.chargepoint_type)}</Text>
            </div>
          </div>
          {
            evseSettings.activity === 'offline' && (
              <div
                className="DisableSelection"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 10,
                  height: 'auto',
                  minHeight: 55,
                  backgroundColor: Colors.LIGHT_GREY,
                  border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                  borderRadius: 10,
                  marginTop: 10,
                }}
              >
                <Text>{`${_('offline.lastOnline', { ns: 'chargepoint' })}:`}</Text>
                {
                  evseSettings.offline_since ? (
                    <Text>{DateTime.fromFormat(evseSettings.offline_since, 'yMMdd TT').setLocale(i18n.language).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</Text>
                  ) : (
                    <Skeleton width={130} />
                  )
                }

              </div>
            )
          }
        </div>
      </div>

      {
        size.width < Breakpoint.sm && (
          <div
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('shortcuts', { ns: 'ui' })}
            </Text>
            <div
              className="DisableSelection"
              style={{
                padding: 10,
                height: 'auto',
                minHeight: 55,
                backgroundColor: Colors.LIGHT_GREY,
                border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                borderRadius: 10,
                marginTop: 10,
              }}
            >
              <Item
                type="button"
                title={_('linkedChargeCards', { ns: 'ui' })}
                onClick={() => navigate(`/chargepoints/${evseSettings.evse_id}/linked-cards`)}
              />
              <div
                style={{
                  borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                  marginTop: 10,
                  paddingTop: 10,
                }}
              >
                <Item
                  type="button"
                  title={_('transactions', { ns: 'ui' })}
                  onClick={() => navigate(`/transactions?chargepoints=${evseSettings.evse_id}`)}
                />
              </div>
            </div>
          </div>
        )
      }
      {
        evseSettings.activity !== 'error' && evseSettings.activity !== 'unavailable' && evseSettings.activity !== 'offline' && (
          <div
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('settings', { ns: 'settings' })}
            </Text>
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <PlugAndChargeSetting
                evseSettings={evseSettings}
                useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                simpleValueChange={simpleValueChange}
                setSelectPlugAndChargeCard={setSelectPlugAndChargeCard}
              />

              <RoamingTariffSetting
                evseSettings={evseSettings}
                useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                simpleValueChange={simpleValueChange}
                setRoamingTariffModalOpen={() => setRoamingTariffModalOpen(true)}
              />

              {
                evseSettings.chargepoint_type !== 'HIDDEN' && (
                  <PublicChargingSetting
                    evseSettings={evseSettings}
                    useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                    simpleValueChange={simpleValueChange}
                  />
                )
              }

              <LedInteractionSetting
                evseSettings={evseSettings}
                useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                simpleValueChange={simpleValueChange}
              />

              <CapacityTariffSetting
                evseSettings={evseSettings}
                useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                openCapacityTariff={() => setCapacityTariffModal(true)}
              />

            </div>
          </div>
        )
      }
      <div
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <ProfiledChargingSetting
          evseSettings={evseSettings}
          useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
          setProfiledChargingModal={setProfiledChargingModal}
        />
      </div>
      {
        (evseSettings.publish_location?.permission !== 'none' && evseSettings.tariff?.permission !== 'none') && (
          <div
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('location', { ns: 'ui' })}
            </Text>
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <PublishLocationSetting
                evseSettings={evseSettings}
                useUpdatedSettings={[updatedSettings, setUpdatedSettings]}
                simpleValueChange={simpleValueChange}
              />
            </div>
          </div>
        )
      }

      {
        evseSettings.activity !== 'offline' && (
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('actions', { ns: 'ui' })}
            </Text>
          </div>
        )
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: 10,
          marginTop: 10,
        }}
      >
        {
          evseSettings.activity !== 'offline' && actions
            .filter(({ visible }) => visible)
            .map(({
              id,
              title,
              subText,
              onClick,
              loading,
              icon,
              color,
              colorScheme,
              onMouseEnter,
              onMouseLeave,
              selected,
              disabled,
            }) => (
              <div
                key={id}
                style={{
                  width: '100%',
                }}
              >
                <Button
                  text={title}
                  subText={subText}
                  type="action"
                  icon={{
                    name: icon.name,
                    type: icon.type,
                    height: icon.height,
                    width: icon.width,
                    set: icon.set,
                    transform: icon.transform,
                  }}
                  selected={selected}
                  color={color}
                  colorScheme={colorScheme}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  loading={loading}
                  disabled={disabled}
                />
              </div>
            ))
        }
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: 10,
          marginTop: 10,
        }}
      >
        {
          evseSettings.activity !== 'offline' && commands
            .filter(({ visible }) => visible)
            .map(({
              id,
              title,
              subText,
              onClick,
              loading,
              icon,
              color,
              colorScheme,
              onMouseEnter,
              onMouseLeave,
              selected,
              disabled,
            }) => (
              <div
                key={id}
                style={{
                  width: '100%',
                }}
              >
                <Button
                  text={title}
                  subText={subText}
                  type="action"
                  icon={{
                    name: icon.name,
                    type: icon.type,
                    height: icon.height,
                    width: icon.width,
                    set: icon.set,
                    transform: icon.transform,
                  }}
                  selected={selected}
                  color={color}
                  colorScheme={colorScheme}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  loading={loading}
                  disabled={disabled}
                />
              </div>
            ))
        }
      </div>
      <ConfirmDialog
        visible={showPublishWarning}
        title={_('settings.publishLocation', { ns: 'chargepoint' })}
        description={_('warning.tariffPublishLocationOff', { ns: 'chargepoint' })}
        accept={{
          text: _('pursue', { ns: 'ui' }),
          onClick: () => {
            setShowPublishWarning(false);
            setPasswordVerificationSetting('tariff');
            setPasswordVerification([null]);
            /* The double function might look weird,
             but it is how storing functions in state works */
            setPasswordConfirmFunction(() => (pass) => updateTariff(null, pass));
          },
        }}
        cancel={{
          text: _('cancel', { ns: 'ui' }),
          onClick: () => setShowPublishWarning(false),
        }}
      />
    </div>
  );
}
